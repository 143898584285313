<template>
  <div id="app">
  </div>
</template>

<script>
    import { THREE, PANOLENS } from '@/util/panolens';

    export default {
        name: 'App',
        watch: {
            selectedPanoramaID: function(val) {
                const view = this.views.find(x => x.id == val);
                this.viewer.setPanorama(view.panorama);

                if(this.selectorObj != null)
                    this.viewer.tweenControlCenterByObject(this.selectorObj);
                else
                    this.viewer.tweenControlCenter(view.lookatPosition);
            }
        },
        data: function() {
            return {
                viewer: null,

                selectorObj: null,

                lastLookatDirection: null,
                selectedPanoramaID: -1,

                infospotMainElement: null,
                infospotTitleElement: null,
                infospotSubtitleElement: null,
                infospotContentElement: null,

                views: [],
                passedData: this.getPassedData() ?? [],
                cachedImages: {}
            }
        },
        methods: {
            initViewer: function(data, container, selectorType, clickCallback, objPosition) {
                if(this.viewer != null)
                    return;

                console.log('creating new viewer');

                this.preparePassedData(data);

                // init viewer with configuration
                this.viewer = new PANOLENS.Viewer({
                    renderer: new THREE.WebGLRenderer({
                        preserveDrawingBuffer: true
                    }),
                    autoHideInfospot: false,
                    container: process.env.NODE_ENV === 'development' ? null : container ?? document.getElementsByClassName('c3-panorama-container')[0],
                    controlButtons: ['fullscreen']
                });

                this.viewer.OrbitControls.noZoom = true;

                // load panoramas and infospots
                this.views.forEach(view => {
                    var that = this;

                    // load obj for PanoramaSelector
                    if(selectorType && objPosition)
                        if(selectorType == 'infospot' || selectorType == 'nextpanorama') {
                            that.selectorObj = that.generateInfospot(500, selectorType == 'nextpanorama' ? PANOLENS.DataImage.Arrow : undefined, undefined, objPosition, undefined);
                            view.panorama.add(that.selectorObj);
                        }

                    // return data to the PanoramaSelector
                    view.panorama.addEventListener('click', function(e) {
                        var isRightMB;

                        if ("which" in e.mouseEvent)  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
                            isRightMB = e.mouseEvent.which == 3; 
                        else if ("button" in e.mouseEvent)  // IE, Opera 
                            isRightMB = e.mouseEvent.button == 2; 

                        if(!isRightMB) {
                            if(selectorType && clickCallback) {
                                if(selectorType == 'infospot' || selectorType == 'nextpanorama') {
                                    let position = that.viewer.getPosition();
                                    clickCallback(position);

                                    if(that.selectorObj)
                                        that.selectorObj.dispose();

                                    that.selectorObj = that.generateInfospot(500, selectorType == 'nextpanorama' ? PANOLENS.DataImage.Arrow : undefined, undefined, position, undefined);
                                    view.panorama.add(that.selectorObj);
                                    that.selectorObj.show();
                                } else if(selectorType == 'lookat')
                                    clickCallback(that.getLookAtDirection(), that.screenshotCurrentScreen());
                            }

                            //that.viewer.getPosition();
                            //that.getLookAtDirection();
                            //that.screenshotCurrentScreen();
                        }
                    });

                    // generate infospots to navigate map
                    if(view.nextPanoramas && view.nextPanoramas.length > 0)
                        this.loadNextPanoramas(view);

                    // generate infospots to display content
                    if(view.infospots && view.infospots.length > 0)
                        this.loadInfospots(view);

                    // generate cubes with videos
                    if(view.videos && view.videos.length > 0)
                        this.loadVideos(view);

                    this.viewer.add(view.panorama);
                });

                // set default panorama
                this.selectedPanoramaID = this.views[0].id;
            },
            loadImage: async function(url) {
                var img = await fetch(url)
                    .then(response => response.blob())
                    .then(blob => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    }));

                this.cachedImages[url] = img;
                return img;
            },
            getPassedData() {
                var data = document.getElementById("panorama_data")?.innerHTML ?? null;
                if(data == null)
                    return null;
                
                return JSON.parse(data);
            },
            preparePassedData: function(data) {
                data.forEach(passedData => {
                    // map basic data
                    let newData = {
                        id: passedData.uid,
                        title: passedData.title,
                        lookatImage: passedData.lookatImage,
                        lookatPosition: new THREE.Vector3(passedData.lookatPosition.x, passedData.lookatPosition.y, passedData.lookatPosition.z),
                        panorama: new PANOLENS.ImagePanorama(passedData.image),
                        icon: passedData.icon,
                        nextPanoramas: [],
                        infospots: [],
                        videos: []
                    };

                    // map nextpanoramas
                    if(passedData.nextpanoramas)
                        passedData.nextpanoramas.forEach(nextPanorama => {
                            let newNextPanoramaData = {
                                id: nextPanorama.uid,
                                pId: nextPanorama.toPanorama,
                                position: new THREE.Vector3(nextPanorama.position.x, nextPanorama.position.y, nextPanorama.position.z),
                                scale: nextPanorama.scale,
                                icon: nextPanorama.icon
                            };

                            newData.nextPanoramas.push(newNextPanoramaData);
                        });

                    // map infospots
                    if(passedData.infospots)
                        passedData.infospots.forEach(infospot => {
                            let newInfospotData = {
                                id: infospot.uid,
                                content: infospot.content,
                                position: new THREE.Vector3(infospot.position.x, infospot.position.y, infospot.position.z),
                                scale: infospot.scale,
                                hovertext: infospot.hoverText
                            };

                            newData.infospots.push(newInfospotData);
                        });

                    // map video
                    if(passedData.videos)
                        passedData.videos.forEach(video => {
                            let newVideoData = {
                                id: video.uid,
                                video: video.video,
                                position: new THREE.Vector3(video.position.x, video.position.y, video.position.z),
                                rotation: new THREE.Euler(video.rotation.x, video.rotation.y, video.rotation.z, 'XYZ'),
                                videoElement: null,
                                videoObject: null,
                                width: video.width,
                                height: video.height,
                                loop: video.loop,
                                autoplay: video.autoplay
                            };

                            newData.videos.push(newVideoData);
                        });

                    // pass new view-object to array
                    this.views.push(newData);
                });
            },
            generateInfospot: function(scale, icon, text, position, clickFunc) {
                scale = scale ?? 350;
                icon = icon ?? PANOLENS.DataImage.Info;

                let infospot = new PANOLENS.Infospot(scale, icon);
                infospot.position.set(position.x, position.y, position.z);

                if(text)
                    infospot.addHoverText(text);

                if(clickFunc)
                    infospot.addEventListener('click', clickFunc);

                return infospot;
            },
            generateNormalVideo: function(src, position, rotation, width, height, loop) {
                width = width ?? 500;
                height = height ?? 500;
                loop = loop ?? true;

                let videoElement = document.createElement('video');
                videoElement.loop = loop;
                videoElement.muted = true;
                videoElement.src = src;

                videoElement.load();         

                const texture = new THREE.VideoTexture( videoElement );
                const videoMaterial = new THREE.MeshBasicMaterial( {map: texture, side: THREE.FrontSide} );
                const geometry = new THREE.BoxGeometry( 1, 1, 1 );
                const object = new THREE.Mesh(geometry, videoMaterial);
                object.scale.x = width;
                object.scale.y = height;
                object.position.x = position.x;
                object.position.y = position.y;
                object.position.z = position.z;
                object.visible = false;

                object.rotation.x = rotation.x;
                object.rotation.y = rotation.y;
                object.rotation.z = rotation.z;

                return { object, videoElement };
            },
            loadNextPanoramas: function(view) {
                view.nextPanoramas.forEach(async entry => {
                    var that = this;

                    let icon = null;
                    if(entry.icon)
                        if(that.cachedImages[entry.icon])
                            icon = that.cachedImages[entry.icon];
                        else
                            icon = await that.loadImage(entry.icon);

                    let infospot = this.generateInfospot(entry.scale, icon ?? PANOLENS.DataImage.Arrow, null, entry.position, function() {
                        that.viewer.setPanorama(that.views.find(x => x.id == entry.pId).panorama);
                    });

                    view.panorama.add(infospot);
                });
            },
            loadInfospots: function(view) {
                view.infospots.forEach(entry => {
                    var that = this;
                    let infospot = this.generateInfospot(entry.scale, entry.icon, entry.hovertext, entry.position, function() {
                        if(that.infospotMainElement 
                            && that.infospotTitleElement 
                            && that.infospotSubtitleElement 
                            && that.infospotContentElement)
                            that.showInfospotContent(entry.content);
                    });

                    view.panorama.add(infospot);
                });
            },
            loadVideos: function(view) {
                view.videos.forEach(entry => {
                    let { object, videoElement } = this.generateNormalVideo(entry.video, entry.position, entry.rotation, entry.width, entry.height, entry.loop);
                    entry.videoElement = videoElement;
                    entry.videoObject = object;
                    view.panorama.add(object);
                });

                view.panorama.addEventListener('click', function(event) {
                    if( event.intersects.length > 0) {
                        var intersect = event.intersects[0].object;

                        if(intersect instanceof THREE.Mesh) {
                            console.log('video clicked');

                            const entry = view.videos.find(x => x.videoObject == intersect);
                            if(!entry) return;

                            if(!entry.videoElement.paused && entry.videoElement.muted)
                                entry.videoElement.muted = false;
                            else if(entry.videoElement.paused)
                                entry.videoElement.play()
                            else
                                entry.videoElement.pause();
                        }
                    }
                });

                view.panorama.addEventListener('leave', function() {
                    console.log(view.title + ':leaving panorama with videos...');

                    view.videos.forEach(entry => {
                        if(entry.videoObject != null)
                            entry.videoObject.visible = false;

                        if(entry.videoElement != null)
                            entry.videoElement.pause();
                    });
                });

                view.panorama.addEventListener('enter-fade-complete', function() {
                    console.log(view.title + ': entering panorama with videos...');

                    view.videos.forEach(entry => {
                        if(entry.videoObject != null)
                            entry.videoObject.visible = true;

                        if(entry.videoElement != null && entry.autoplay)
                            entry.videoElement.muted = true;
                            entry.videoElement.play();
                    });
                });
            },
            screenshotCurrentScreen: function() {
                var strMime = "image/jpeg";
                var imgData = this.viewer.getRenderer().domElement.toDataURL(strMime);
                // var link = document.createElement('a');
                // link.download = 'image.jpg';
                // link.href = imgData;
                // link.click();
                return imgData;
            },
            getLookAtDirection: function() {
                var position = new THREE.Vector3();

                this.viewer.getCamera().getWorldDirection(position);
                position.multiplyScalar(1000);
                position.x = -position.x;

                return new THREE.Vector3(
                    position.x.toFixed(2),
                    position.y.toFixed(2),
                    position.z.toFixed(2),
                );
            },
            showInfospotContent: function(content) {
                var that = this;
                this.infospotTitleElement.innerHTML = content.title ?? '';
                this.infospotSubtitleElement.innerHTML = content.subtitle ?? '';
                
                this.infospotContentElement.innerHTML = '';
                content.tt_content.forEach(tt_content => {
                    that.infospotContentElement.insertAdjacentHTML('beforeend', tt_content.bodyText);
                });

                this.infospotMainElement.style.display = '';
            },
            setPanorama: function(id) {
                this.selectedPanoramaID = id;
            },
            setControls: function(enabled) {
                if(enabled) {
                    this.viewer.enableControl();

                    if(this.lastLookatDirection != null)
                        this.viewer.tweenControlCenter(this.lastLookatDirection);
                }
                else {
                    this.lastLookatDirection = this.getLookAtDirection();
                    this.viewer.disableControl();
                }
            },
            setInfospotContainers: function(mainElement, titleElement, subtitleElement, contentElement) {
                this.infospotMainElement = mainElement;
                this.infospotTitleElement = titleElement;
                this.infospotSubtitleElement = subtitleElement;
                this.infospotContentElement = contentElement;
            }
        },
        mounted: function() {
            window.vm = this;

            var data = this.getPassedData();

            if(data != null)
                this.initViewer(data);
        },
        beforeDestroy: function() {
            this.viewer.destroy();
            this.viewer = null;
        }
    }
</script>

<style lang="scss">
    body, html {
        margin: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #000;
    }

    #panolens-separate-container:-webkit-full-screen{
        left: 0;
        top: 0;
    }
    #panolens-separate-container:-moz-full-screen{
        left: 0;
        top: 0;
    }
    #panolens-separate-container:-ms-fullscreen{
        left: 0;
        top: 0;
    }
    #panolens-separate-container:fullscreen {
        left: 0;
        top: 0;
    }
</style>
